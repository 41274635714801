import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Layout from "components/Layout/Layout"
import Hero from "components/FlexibleContent/Blocks/Hero"
import Text from "components/shared/Text"

const Page404 = (props) => {
    const { 
		desktopImage404,
		tabletImage404,
		mobileImage404,
		title404, 
		text404
	} = props.data

	const block = {
		desktopImage: desktopImage404,
		tabletImage: tabletImage404,
		mobileImage: mobileImage404,
		title: title404
	}

    return (
        <Layout 
			// path={data.uri} 
			// seo={data.seo} 
		>
			<Hero block={block} />

			<Text as="div" center uppercase xl  condensed secondary dangerouslySetInnerHTML={{ __html: text404 }} className="pb-5" />
        </Layout>
    )
}

const Page404Export = () => {
	return(
		<StaticQuery
			query={graphql`
				query {
					allWp {
						edges {
							node {
								...optionsFragment
							}
						}
					}
				}
			`}
			render={data => {
				return (
					<Page404 data={data.allWp.edges[0].node.options.acfOptions} />
				)
			}}
		/>
	)
}

export default Page404Export